import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Select, Alert } from 'antd';
import {
    MailOutlined,
    RocketOutlined,
    TwitterOutlined,
    SlackOutlined,
    CheckSquareOutlined,
} from '@ant-design/icons';
import { SiMinutemailer } from 'react-icons/si';
import axios from 'axios';

import '../styles/plan.scss';
import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';
import { Firebase } from '../config/firebase';
import { Note } from '@geist-ui/core';
import { navigate } from 'gatsby';

const { Option } = Select;

const Contact = () => {
    const [planType, setPlanType] = useState('yearly');
    const [loading, setLoading] = useState(false);
    const [userPlan, setUserPlan] = useState('Free');
    const [custID, setCustID] = useState('');

    const [uid, setUid] = useState('');

    function onChange(user) {
        if (user) {
            setUid(user.uid);
        } else {
            navigate('/login');
        }
    }

    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(onChange);

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    const [sendingMessage, setSendingMessage] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');

    const submitContactForm = (values) => {
        setSendingMessage(true);
        values.uid = uid;
        values['-'] = '-';
        values.source = 'Form with Sheet';
        axios
            .post(
                `https://v1.nocodeapi.com/betauser/telegram/ApHIRusmZfkIFvOQ`,
                values
            )
            .then((res) => {
                setSendingMessage(false);
                setSuccessMsg(
                    'Thanks for submitting this form. We will get back to you ASAP. :)'
                );
                setTimeout(() => {
                    setSuccessMsg('');
                }, 3000);
            })
            .catch((err) => {
                setSuccessMsg('Opps! Sorry there are some issues.');
                setTimeout(() => {
                    setSuccessMsg('');
                }, 3000);
            });
    };

    return (
        <>
            <SEO title="Contact US — Form with Sheet" />
            <FormContainer menuKey="6" pageTitle="Contact">
                {loading ? (
                    'Loading...'
                ) : (
                    <div className="plan-page" style={{ width: 600 }}>
                        <h2>Contact US</h2>
                        <p>
                            If you have any problem or query, then drop a
                            message to us and will get back to you withing 12
                            hours.
                        </p>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={submitContactForm}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter email address.',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="Email address"
                                />
                            </Form.Item>
                            <Form.Item
                                name="subject"
                                rules={[{ required: true }]}
                            >
                                <Select
                                    prefix={<MailOutlined />}
                                    placeholder={
                                        <React.Fragment>
                                            <CheckSquareOutlined
                                                style={{ color: '#000' }}
                                            />
                                            &nbsp; Select a subject
                                        </React.Fragment>
                                    }
                                    allowClear
                                >
                                    <Option value="General Query">
                                        General Query
                                    </Option>
                                    <Option value="New Integration Request">
                                        New Integration Request
                                    </Option>
                                    <Option value="Request Quote">
                                        Request Quote
                                    </Option>
                                    <Option value="Billing Query">
                                        Billing Query
                                    </Option>
                                    <Option value="Technical Query">
                                        Technical Query
                                    </Option>
                                    <Option value="Bug Report">
                                        Bug Report
                                    </Option>
                                    <Option value="Others">Others</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="message"
                                rules={[{ required: true }]}
                            >
                                <Input.TextArea />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    icon={
                                        <SiMinutemailer
                                            style={{ marginRight: 10 }}
                                            className={
                                                sendingMessage && 'shake-icon'
                                            }
                                        />
                                    }
                                >
                                    {sendingMessage ? 'Sending...' : 'Send it'}
                                </Button>
                            </Form.Item>
                        </Form>
                        {successMsg !== '' && (
                            <Note label="Done">{successMsg}</Note>
                        )}
                    </div>
                )}
            </FormContainer>
        </>
    );
};

export default Contact;
